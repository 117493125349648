import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/prices.scss"
const Prices = ({ data }) => (
  <Layout seoMetaTags={data?.datoCmsPricespage?.seoMetaTags} page="Hinnat">
    <div className="prices">
      <ul className="pricesTable">
        {data.datoCmsPricespage.pricesTable.map(row => (
          <li>
            <div className="content">
              <h3>{row.title}</h3>
              <div
                className='infoTexts'
                dangerouslySetInnerHTML={{
                  __html: row.contentNode?.childMarkdownRemark.html,
                }}
              />
            </div>
          </li>
        ))}
      </ul>
      <div className="content"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsPricespage.infoTextNode.childMarkdownRemark.html,
        }}
      />
    </div>
  </Layout>
)
export const query = graphql`
  query pricesQuery {
    datoCmsPricespage {
      seoMetaTags{
        ...GatsbyDatoCmsSeoMetaTags
      }
      infoTextNode {
        childMarkdownRemark {
          html
        }
      }
      callToAction {
        url
        title
        image {
          url
        }
      }
      pricesTable {
        title
        contentNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default Prices
